<template>
  <v-container fluid>
    <v-btn class="ma-2" tile large color="green darken-2" @click="printform()" icon>
      <v-icon>mdi-printer</v-icon>
    </v-btn>
    <h2>{{currentCase.participantDetails.firstname}} {{currentCase.participantDetails.lastname}}'s General Notes</h2>
    <v-row>
      <v-col cols="12">
        <v-data-table
          id="list-table"
          ref="datatable"
          :headers="currentHeaders"
          :items="notes"
          item-key="index"
          class="mr-2"
          :hide-default-footer="true"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td>{{ props.item.title }}</td>
              <td>{{ props.item.details}}</td>
              <td>{{ props.item.createdBy.email}}</td>
              <td>{{ moment(props.item.createdOn).format("DD-MM-YYYY")}}</td>
              <td>{{ props.item.modifiedBy.email}}</td>
              <td>{{ moment(props.item.modifiedOn).format("DD-MM-YYYY")}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <br />
    <br />
  </v-container>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as notesService from "../../../../services/generalNotesService";

Vue.use(VeeValidate);
import moment from "moment";
export default {
  name: "notes",
  components: {},
  data: () => ({
    editedIndex: -1,
    vm: {},

    dialog: false,
    notes: [],
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 }
    ],
    currentHeaders: [
      { text: "Title", value: "title" },
      { text: "Description", value: "description" },
      { text: "created by", value: "created by" },
      { text: "Created on", value: "createdon" },
      { text: "Last modified by", value: "modified by" },
      { text: "Modified on", value: "createdon" }
    ]
  }),
  mounted() {
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    moment: function(date) {
      return date != undefined ? moment(date) : moment();
    },
    printform() {
      print();
    },
    init() {
      this.vm = {};
      notesService
        .list(this.currentOrganisation.id, this.currentCase.id)
        .then(result => {
          let notes = [];
          result.docs.map(doc => {
            const note = doc.data();
            note.id = doc.id;
            notes.push(note);
          });
          this.notes = notes;
        });
    }
  }
};
</script>
